import { ComponentProps, forwardRef } from 'react'
import { CheckIcon } from 'lucide-react'
import * as StyledTabs from './styled/tabs'
import { cx } from 'styled-system/css'

const { Trigger: StyledTrigger, TriggerContent: StyledTriggerContent, TriggerIconWrapper, TriggerIcon, TriggerIconText, TriggerText, ...rest } = StyledTabs

/* 下記拡張 */
type TriggerProps = ComponentProps<typeof StyledTrigger>
const Trigger = forwardRef<HTMLButtonElement, TriggerProps>((props, ref) => {
  const { className, ...rest } = props
  return <StyledTrigger ref={ref} {...rest} className={cx('group', className)} />
})

type TriggerContentProps = {
  num: number
  label: string
}
const TriggerContent = forwardRef<HTMLDivElement, TriggerContentProps>(({ num, label, ...rest }, ref) => {
  return (
    <StyledTriggerContent ref={ref} {...rest}>
      <TriggerIconWrapper>
        <TriggerIcon asChild>
          <CheckIcon strokeWidth={4} />
        </TriggerIcon>
        <TriggerIconText>{num}</TriggerIconText>
      </TriggerIconWrapper>
      <TriggerText>{label}</TriggerText>
    </StyledTriggerContent>
  )
})

// TODO: ここのexport良い感じにしたい
export const Tabs = {
  ...rest,
  Trigger,
  TriggerContent,
}
export type TabsRootProviderProps = StyledTabs.RootProviderProps
export type TabsRootProps = StyledTabs.RootProps
/* 上記拡張 */
